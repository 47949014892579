<template>
  <el-dialog
    :title="`“${detail.couponName}” 领取详情`"
    v-bind="$attrs"
    v-on="$listeners"
    append-to-body
    width="900px"
    class="f-dialog"
    @opened="handleOpen"
  >
    <el-table border :data="receiveList" size="mini">
      <el-table-column prop="couponCode" label="优惠券编号"></el-table-column>
      <el-table-column prop="customerName" label="用户昵称"></el-table-column>
      <el-table-column prop="cellphone" label="手机号"></el-table-column>
      <el-table-column prop="receiveDate" label="领取时间"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-button
            v-if="!row.useStatus"
            size="mini"
            type="primary"
            round
            @click="checkCoupon(row)"
            >核销</el-button
          >
          <div v-if="row.useStatus">
            已核销，{{ row.checkedUser }}，{{ row.checkedDate }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="receiveTotal"
      @change="getEstateCouponReceiveList"
    ></f-pagination>
  </el-dialog>
</template>

<script>
import { getEstateCouponReceiveList, checkCoupon } from "@/api/estate";
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      receiveList: [],
      receiveTotal: 0,
      queryData: {
        couponId: null,
        couponType: 1,
        estateId: null,
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  methods: {
    handleOpen() {
      this.queryData.pageNum = 1;
      this.getEstateCouponReceiveList();
    },
    // 查询领取人列表
    async getEstateCouponReceiveList() {
      const res = await getEstateCouponReceiveList({
        ...this.queryData,
        estateId: this.$estateInfo.estateId,
        couponId: this.detail.couponId,
        couponType: this.detail.couponType
      });
      if (res) {
        const { total, list } = res;
        this.receiveList = list;
        this.receiveTotal = total;
      }
    },
    // 核销优惠券
    checkCoupon(item) {
      this.$confirm("核销后无法撤回，确定要核销吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const res = await checkCoupon({
          id: item.id,
          token: this.$userInfo.token,
          userId: this.$userInfo.userId
        });
        if (res) {
          this.getEstateCouponReceiveList();
          this.$message({
            type: "success",
            message: "核销成功!"
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .f-pagination {
    left: 0 !important;
    right: 0 !important;
  }
}
</style>
