<template>
  <el-dialog
    :title="`${formData.couponId ? '编辑' : '发布'}优惠券`"
    v-bind="$attrs"
    v-on="$listeners"
    append-to-body
    width="500px"
    :close-on-click-modal="false"
    @close="resetForm"
    class="f-dialog"
  >
    <el-form
      :model="formData"
      label-position="left"
      label-width="100px"
      size="small"
      ref="form"
    >
      <el-form-item
        label="名称"
        prop="couponName"
        :rules="[$formRules.required()]"
      >
        <el-input v-model="formData.couponName"></el-input>
      </el-form-item>
      <el-form-item
        label="面值类型"
        prop="faceValueType"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.faceValueType">
          <el-radio :label="1">按现金</el-radio>
          <el-radio :label="2">按折扣</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="面值"
        prop="faceValue"
        :rules="[$formRules.required(), $formRules.int]"
      >
        <el-input v-model="formData.faceValue" type="number">
          <p slot="append">元</p>
        </el-input>
      </el-form-item>
      <el-form-item
        label="初始领取数"
        prop="initialNum"
        :rules="[$formRules.required(), $formRules.int]"
      >
        <el-input v-model="formData.initialNum" type="number">
          <p slot="append">份</p>
        </el-input>
      </el-form-item>
      <el-form-item
        label="发行数量"
        prop="publishNum"
        :rules="[$formRules.required(), $formRules.int]"
      >
        <el-input v-model="formData.publishNum" type="number">
          <p slot="append">份</p>
        </el-input>
      </el-form-item>
      <el-form-item
        label="有效期"
        prop="expireDate"
        :rules="[$formRules.required()]"
      >
        <el-date-picker
          v-model="formData.expireDate"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="使用限制">
        <el-input v-model="formData.useLimits"></el-input>
      </el-form-item>
      <el-form-item label="详细说明">
        <el-input type="textarea" v-model="formData.description"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="resetForm">重置</el-button>
      <el-button type="primary" @click="submitForm">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editEstateCoupon } from "@/api/estate";
const formData = {
  estateId: null,
  token: null,
  userId: null,
  couponId: null,
  description: "", //详细说明
  expireDate: "", // 有效期
  faceValue: "", // 面值
  publishNum: "", // 发行数量
  couponType: 1,
  faceValueType: 1, // 按现金 按折扣
  couponName: "", // 优惠券名称
  useLimits: "", //使用限制
  initialNum: "" //初始领取数量
};
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: this.$deepClone(formData)
    };
  },
  watch: {
    detail: {
      deep: true,
      handler(val) {
        if (Object.keys(val).length) {
          Object.assign(this.formData, val);
        }
      }
    }
  },
  methods: {
    resetForm() {
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields(); // 清空表单数据
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await editEstateCoupon({
          ...this.formData,
          //时间提交要的这种格式 列表返回的又是格式化后的格式 所以这里提交的时候再格式化一下 防止报错
          expireDate: new Date(this.formData.expireDate).toISOString(),
          estateId: this.$estateInfo.estateId,
          token: this.$userInfo.token,
          userId: this.$userInfo.token
        });
        if (res) {
          this.resetForm();
          this.$showSuccess("提交成功");
          this.$emit("update:visible", false);
          this.$emit("success");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
