var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": "".concat(_vm.formData.couponId ? '编辑' : '发布', "\u4F18\u60E0\u5238"),
      "append-to-body": "",
      "width": "500px",
      "close-on-click-modal": false
    },
    on: {
      "close": _vm.resetForm
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-position": "left",
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "名称",
      "prop": "couponName",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formData.couponName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "couponName", $$v);
      },
      expression: "formData.couponName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "面值类型",
      "prop": "faceValueType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.faceValueType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "faceValueType", $$v);
      },
      expression: "formData.faceValueType"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("按现金")]), _c('el-radio', {
    attrs: {
      "label": 2
    }
  }, [_vm._v("按折扣")])], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "面值",
      "prop": "faceValue",
      "rules": [_vm.$formRules.required(), _vm.$formRules.int]
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formData.faceValue,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "faceValue", $$v);
      },
      expression: "formData.faceValue"
    }
  }, [_c('p', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("元")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "初始领取数",
      "prop": "initialNum",
      "rules": [_vm.$formRules.required(), _vm.$formRules.int]
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formData.initialNum,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "initialNum", $$v);
      },
      expression: "formData.initialNum"
    }
  }, [_c('p', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("份")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "发行数量",
      "prop": "publishNum",
      "rules": [_vm.$formRules.required(), _vm.$formRules.int]
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formData.publishNum,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "publishNum", $$v);
      },
      expression: "formData.publishNum"
    }
  }, [_c('p', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("份")])])], 1), _c('el-form-item', {
    attrs: {
      "label": "有效期",
      "prop": "expireDate",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    model: {
      value: _vm.formData.expireDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "expireDate", $$v);
      },
      expression: "formData.expireDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "使用限制"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.formData.useLimits,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "useLimits", $$v);
      },
      expression: "formData.useLimits"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "详细说明"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("重置")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("保存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }