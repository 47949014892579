var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": "\u201C".concat(_vm.detail.couponName, "\u201D \u9886\u53D6\u8BE6\u60C5"),
      "append-to-body": "",
      "width": "900px"
    },
    on: {
      "opened": _vm.handleOpen
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-table', {
    attrs: {
      "border": "",
      "data": _vm.receiveList,
      "size": "mini"
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "couponCode",
      "label": "优惠券编号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "customerName",
      "label": "用户昵称"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "cellphone",
      "label": "手机号"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "receiveDate",
      "label": "领取时间"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [!row.useStatus ? _c('el-button', {
          attrs: {
            "size": "mini",
            "type": "primary",
            "round": ""
          },
          on: {
            "click": function click($event) {
              return _vm.checkCoupon(row);
            }
          }
        }, [_vm._v("核销")]) : _vm._e(), row.useStatus ? _c('div', [_vm._v(" 已核销，" + _vm._s(row.checkedUser) + "，" + _vm._s(row.checkedDate) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.receiveTotal
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getEstateCouponReceiveList
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }