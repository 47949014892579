<template>
  <div class="base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">优惠券</h3>
        <p class="summary">
          该优惠券只发布到“房猫找房小程序”平台
        </p>
      </div>
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="editDialogVisible = true"
        >发布优惠券</el-button
      >
    </div>
    <el-table class="mt20" :data="list">
      <el-table-column label="券名称" prop="couponName"></el-table-column>
      <el-table-column label="面值" width="100">
        <template v-slot="{ row }">
          {{ row.faceValue }}元
        </template></el-table-column
      >
      <el-table-column label="发行数量" width="100">
        <template v-slot="{ row }"> {{ row.publishNum }}份 </template>
      </el-table-column>
      <el-table-column label="初始领取数" width="100">
        <template v-slot="{ row }"> {{ row.initialNum }}份 </template>
      </el-table-column>
      <el-table-column label="实际领取数" width="100">
        <template v-slot="{ row }"> {{ row.receive || 0 }}份 </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template v-slot="{ row }">
          {{ row.status === 1 ? "发行中" : "已下架" }}
        </template>
      </el-table-column>
      <el-table-column label="使用限制" prop="useLimits"></el-table-column>
      <el-table-column label="详细说明" prop="description"></el-table-column>
      <el-table-column label="发行时间" prop="publishDate"></el-table-column>
      <el-table-column label="操作" width="200">
        <template v-slot="{ row }">
          <div class="btn-group">
            <el-link
              type="primary"
              @click="activeHandler(row, 'detailDialogVisible')"
              >查看</el-link
            >
            <el-link
              type="primary"
              @click="activeHandler(row, 'editDialogVisible')"
              >编辑</el-link
            >
            <el-link
              :type="row.status === 1 ? 'danger' : 'primary'"
              @click="updateEstateCouponStatus(row, row.status === 1 ? 2 : 1)"
              >{{ row.status === 1 ? "下架" : "上架" }}</el-link
            >
            <el-link type="danger" @click="updateEstateCouponStatus(row, -1)"
              >删除</el-link
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getEstateCouponList"
    ></f-pagination>
    <edit-dialog
      :visible.sync="editDialogVisible"
      :detail="activeRow"
      @success="getEstateCouponList"
    ></edit-dialog>
    <detail-dialog
      :visible.sync="detailDialogVisible"
      :detail="activeRow"
    ></detail-dialog>
  </div>
</template>

<script>
import { getEstateCouponList, updateEstateCouponStatus } from "@/api/estate";
import EditDialog from "./_components/edit";
import DetailDialog from "./_components/detail";
export default {
  components: {
    EditDialog,
    DetailDialog
  },
  data() {
    return {
      editDialogVisible: false,
      detailDialogVisible: false,
      list: [],
      total: 0,
      activeRow: {},
      queryData: {
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.getEstateCouponList();
  },
  methods: {
    activeHandler(row, visibleKey) {
      this.activeRow = { ...row, now: Date.now() };
      this[visibleKey] = true;
    },
    // y优惠券列表
    async getEstateCouponList() {
      const res = await getEstateCouponList({
        estateId: this.$estateInfo.estateId,
        token: this.$userInfo.token,
        userId: this.$userInfo.userId,
        couponType: 1,
        ...this.queryData
      });
      if (res) {
        const { list, total } = res;
        this.list = list;
        this.total = total;
      }
    },
    // 上架 下架
    async updateEstateCouponStatus({ couponId }, status) {
      const res = await updateEstateCouponStatus({
        couponId,
        status,
        token: this.$userInfo.token,
        userId: this.$userInfo.userId
      });
      if (res) {
        this.getEstateCouponList();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.btn-group {
  & > * {
    margin: 0 6px;
  }
}
</style>
